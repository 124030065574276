import { Redirect, Route, BrowserRouter, Switch } from 'react-router-dom';
import 'i18n';

import 'App.css';
import Home from 'components/Home';
import Admin from 'components/Admin';
import Game from 'components/Game';
import PrivateRoute from 'components/PrivateRoute';

function App() {
  return (
    <div className='flex min-h-screen'>
      <BrowserRouter basename="/webgame/webgame-client/build">
        <Switch>
          <Route exact path='/admin' component={Admin} />
          <PrivateRoute exact path='/:slug' component={Game} />
          <Route exact path='/' component={Home} />
          <Redirect from='*' to='/' />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
