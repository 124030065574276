import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getStatus, formatTime } from 'utils';
import classnames from 'classnames';
import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}${!!process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ''}`;

const PasswordRow = props => {
  const { pwd, onClick, onDelete, onUpdate, authToken } = props;
  const [t] = useTranslation();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const date = pwd.date ? new Date(pwd.date) : null;
  const expireDate = (!!date && pwd.expires > 0) ? new Date(date.getTime() + pwd.expires * 1000) : null;

  const deletePassword = useCallback(async () => {
    try {
      await axios.delete(`${API_URL}/passwords/${pwd._id}`, { headers: { 'x-auth-token': authToken } });
      onDelete(pwd._id);
    } catch (error) {
      console.error(error)
    }
  }, [pwd, authToken, onDelete]);

  const updateStatus = useCallback(async e => {
    try {
      await axios.put(`${API_URL}/passwords/${pwd._id}`, { ...pwd, status: e.target.value }, { headers: { 'x-auth-token': authToken } });
      onUpdate(pwd._id);
    } catch (error) {
      console.error(error)
    }
  }, [pwd, onUpdate, authToken]);

  const updateDuration = useCallback(async e => {
    try {
      const expires = parseFloat(e.target.value) === -1 ? 0 : parseFloat(e.target.value) * 60;
      await axios.put(`${API_URL}/passwords/${pwd._id}`, { ...pwd, expires }, { headers: { 'x-auth-token': authToken } })
      onUpdate(pwd._id);
    } catch (error) {
      console.error(error)
    }
  }, [pwd, onUpdate, authToken]);

  const updateRole = useCallback(async e => {
    try {
      await axios.put(`${API_URL}/passwords/${pwd._id}`, { ...pwd, role: e.target.value }, { headers: { 'x-auth-token': authToken } })
      onUpdate(pwd._id);
    } catch (error) {
      console.error(error)
    }
  }, [pwd, onUpdate, authToken]);

  return (
    <tr className={classnames({ 'bg-success': pwd.admin })} onClick={onClick}>
      <td className={classnames('px-3 py-4 whitespace-nowrap text-sm text-gray-900', { 'text-opacity-30 bg-gray-200': showConfirmDelete })}><input type='checkbox' className='pwd-checkbox' id={`checkbox-${pwd._id}`} data-id={pwd._id} style={{ pointerEvents: 'none' }} /></td>

      <td className={classnames('px-6 py-4 whitespace-nowrap text-sm text-gray-900', { 'text-opacity-30 bg-gray-200': showConfirmDelete })}><span onClick={e => e.stopPropagation()}>{pwd.value}</span></td>

      <td className={classnames('px-6 py-4 whitespace-nowrap text-sm text-gray-900', { 'text-opacity-30 bg-gray-200': showConfirmDelete })} style={{ minWidth: 180 }} onClick={e => e.stopPropagation()}>
        <select name='status' defaultValue={pwd.status} onChange={updateStatus} className='focus:ring-primary focus:border-primary flex-1 block w-full rounded sm:text-sm border-gray-300'>
          <option value={0}>{t(getStatus(0))}</option>
          <option value={1}>{t(getStatus(1))}</option>
          <option value={2}>{t(getStatus(2))}</option>
        </select>
      </td>

      <td className={classnames('px-6 py-4 whitespace-nowrap text-sm text-gray-900', { 'text-opacity-30 bg-gray-200': showConfirmDelete })} style={{ minWidth: 150 }} onClick={e => e.stopPropagation()}>
        <select name='duration' id='duration' defaultValue={pwd.expires > 0 ? pwd.expires / 3600 : -1} onChange={updateDuration} className='focus:ring-primary focus:border-primary flex-1 block w-full rounded sm:text-sm border-gray-300'>
          <option value={0.5}>{formatTime(0.5 * 60)}</option>
          <option value={1}>{formatTime(1 * 60)}</option>
          <option value={1.5}>{formatTime(1.5 * 60)}</option>
          <option value={2}>{formatTime(2 * 60)}</option>
          <option value={2.5}>{formatTime(2.5 * 60)}</option>
          <option value={3}>{formatTime(3 * 60)}</option>
          <option value={-1}>{'∞'}</option>
        </select>
      </td>

      <td className={classnames('px-6 py-4 text-sm text-gray-900', { 'text-opacity-30 bg-gray-200': showConfirmDelete })}>{date ? `${new Date(date).toLocaleString()}` : '-'}</td>

      <td className={classnames('px-6 py-4 text-sm text-gray-900', { 'text-opacity-30 bg-gray-200': showConfirmDelete })}>{(date && expireDate) ? `${new Date(expireDate).toLocaleString()}` : '-'}</td>

      <td className={classnames('px-6 py-4 whitespace-nowrap text-sm text-gray-900', { 'text-opacity-30 bg-gray-200': showConfirmDelete })}>{pwd.activations || (pwd.status !== 0 ? 1 : 0)}</td>

      <td className={classnames('px-6 py-4 whitespace-nowrap text-sm text-gray-900', { 'text-opacity-30 bg-gray-200': showConfirmDelete })} style={{ minWidth: 180 }} onClick={e => e.stopPropagation()}>
        <select name='role' defaultValue={pwd.role} onChange={updateRole} className='focus:ring-primary focus:border-primary flex-1 block w-full rounded sm:text-sm border-gray-300'>
          <option value={'participant'}>{t('Participant')}</option>
          <option value={'animator'}>{t('Animator')}</option>
        </select>
      </td>
      <td className={classnames('px-6 py-4 whitespace-nowrap text-sm text-gray-900', { 'bg-gray-200': showConfirmDelete })} style={{ minWidth: 240 }} onClick={e => e.stopPropagation()}>
        {!showConfirmDelete && <button className='inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:mr-3 sm:w-full sm:text-sm' onClick={() => setShowConfirmDelete(true)}>{t('Delete')}</button>}
        {showConfirmDelete && <>
          <button onClick={() => setShowConfirmDelete(false)} className='mt-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:mr-3 sm:w-auto sm:text-sm'>{t('Cancel')}</button>
          <button className='px-3 py-2 bg-red-600 rounded font-bold text-white text-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-700' onClick={deletePassword}>{t('Confirm')}</button>
        </>}
      </td>
    </tr>
  )
}

export default PasswordRow;
PasswordRow.propTypes = {
  pwd: PropTypes.object.isRequired,
  authToken: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};
