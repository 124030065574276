import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import classnames from 'classnames';

import Dashboard from 'components/Dashboard';

const API_URL = `${process.env.REACT_APP_API_URL}${!!process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ''}`;
const SECRET = process.env.REACT_APP_JWT_SECRET;

const Admin = () => {
	console.log('1');
  const [t] = useTranslation();
  const [cookies, setCookie, removeCookie] = useCookies([`token`]);
  const [logged, setLogged] = useState(!!cookies.token);
  const [wrongCredentials, setWrongCredentials] = useState(false);

  const tryLogin = useCallback(async e => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.target;
    const { username, password } = form;
    if (username.value.match(/^\s*$/) || password.value.match(/^\s*$/)) {
      return;
    }

    try {
      const res = await axios.post(`${API_URL}/auth/login`, { username: username.value, password: password.value });
      const { data } = res;
      const { token } = data;
      setCookie('token', token);
      setWrongCredentials(false);
      setLogged(true);
    } catch (err) {
      console.error(err)
      if (err.response && err.response.status === 401) {
        setWrongCredentials(true);
      }
    }
  }, [setLogged, setCookie, setWrongCredentials])

  useEffect(() => {
    const token = cookies.token;
    if (token) {
      try { 
        const { exp } = jwt.decode(token, SECRET);
        const dateNow = new Date();
        if (!!exp && exp < dateNow.getTime() / 1000) {
          removeCookie('token');
          setLogged(false);
        } else {
          setLogged(true);
        }
      } catch (error) {
        console.error(error.message)
        removeCookie('token');
        setLogged(false);
      }
    } else {
      setLogged(false);
    }
  }, [logged, setLogged, cookies, removeCookie]);

  return (
    logged
    ? <Dashboard />
    : <div className='min-h-screen mx-auto w-full flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8'>
      <div className='max-w-md w-full space-y-8'>
        <div>
          <h2 className='text-center text-3xl font-extrabold text-gray-900'>{t('Sign in')}</h2>
        </div>
        <form className={classnames('mt-8 space-y-6', { 'incorrect': wrongCredentials })} action='' method='POST' onSubmit={tryLogin}>
          <input type='hidden' name='remember' value='true' />
          <div className='rounded-md shadow-sm -space-y-px'>
            <div>
              <label htmlFor='username' className='sr-only'>{t('Username')}</label>
              <input id='username' name='username' type='text' autoComplete='username' required className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm' placeholder={t('Username')} onChange={() => setWrongCredentials(false)} />
            </div>
            <div>
              <label htmlFor='password' className='sr-only'>{t('Password')}</label>
              <input id='password' name='password' type='password' autoComplete='current-password' required className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm' placeholder={t('Password')} onChange={() => setWrongCredentials(false)} />
            </div>
          </div>
          {/* <div className='flex items-center justify-between'>
            <div className='flex items-center'>
              <input id='remember_me' name='remember_me' type='checkbox' className='h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded' />
              <label htmlFor='remember_me' className='ml-2 block text-sm text-gray-900'>{t('Remember me')}</label>
            </div>
          </div> */}
          <div>
            <button type='submit' className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary'>
              <span className='absolute left-0 inset-y-0 flex items-center pl-3'>
                <svg className='h-5 w-5 text-primary group-hover:text-primary' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='dark' aria-hidden='true'><path fillRule='evenodd' d='M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z' clipRule='evenodd' /></svg>
              </span>
              {t('Sign in')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Admin;