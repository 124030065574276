import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import classnames from 'classnames';
import PinField from 'react-pin-field';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


const API_URL = `${process.env.REACT_APP_API_URL}${!!process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ''}`;
const background = `${process.env.PUBLIC_URL}` + "/background.png";
const logoTTW = `${process.env.PUBLIC_URL}` + "/logoTTW.png";
const logoMSD = `${process.env.PUBLIC_URL}` + "/logoMSD.png";

const Home = () => {
  const [t] = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [slug] = useState('tectheway'); // Set the slug to your game slug. Especially useful if there are more than one
  const [passwordStatus, setPasswordStatus] = useState(0);
  const history = useHistory();
  const [, setCookie] = useCookies([`license-${slug}`]);
  const pinField = useRef();

  const submitPassword = useCallback(async pin => {
    try {
		const pinValue = document.getElementById("pinField").value;
      const res = await axios.post(`${API_URL}/passwords/activate`, { game: slug, value: pinValue });
      const { data } = res;
      const { token } = data;
      if (!token) {
        throw new Error('Invalid token');
      }
      // The password is correct and successfully activated
      setPasswordStatus(1);

      // Keep token in cookie, then it will redirect
      setTimeout(() => {
        setShowModal(false);
        setCookie(`license-${slug}`, token);
        // The token is valid and not expired : Go to the game
        history.push(`/${slug}`);
      }, 2000);
    } catch (error) {
      setPasswordStatus(-1);
      console.error(error.message);
    }
  }, [slug, history, setCookie, setShowModal]);

  const onPinChange = useCallback(value => {
    if (value.length !== pinField.current.length) {
      setPasswordStatus(0);
    }
  }, [pinField, setPasswordStatus]);

  useEffect(() => {
    if (!showModal) {
      setPasswordStatus(0);
    }
  }, [showModal, setPasswordStatus]);
  
    const RedirectToPrivacy = () => {
    history.push("https://www.msdprivacy.com/fr/fra/");
  }
  
  
  return (
    <>	
      <div style={{ backgroundSize: 'cover', height: '100%', backgroundImage: `url(${background})` }} className='min-h-screen mx-auto w-full flex  items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8'>
			<div  className='max-w-md text-center'>
			  <img src={logoTTW} style={{width: '60%',height: '60%'}} className='min-h-	screen mx-auto w-full flex flex-col items-center justify-center'/>
			  <h1 className="mt-4 mb-12 text-x5 text-white">{t('InteractivWorkshop')}</h1>
			  <button 
			  style={{
					border:'solid 2px transparent',
					backgroundImage:'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #0B81BD, #B52C76)',
					backgroundOrigin:'border-box',
					backgroundClip:'content-box, border-box',
					boxShadow:'2px 1000px 1px #fff inset',
					}}
			  type="button" onClick={() => setShowModal(true)} className="group relative w-1/2 mt-12 justify-center py-2 border text-sm font-medium text-black bg-white hover:bg-white-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">{t('Start')}			
			  </button>

		<div style={{ position: 'absolute', height:'5%', bottom: '4%', left: '50%', marginLeft: -100 }}>
           <div className="text-white" style={{ position: 'absolute', width: 200}}>
		   <a target="_blank" href={t('PrivacyLink')}>{t('Policy')}</a>
           </div>
		</div>

		
			 <img  src={logoMSD} style={{position:'absolute', height:'5%', bottom: '2%', left: '3%'}} />
			 <h1 className="text-white" style={{position:'absolute', height:'2%', bottom: '3%', right: '3%'}}>{t('Version')}</h1> 	 	 
			</div>
	  </div>
	  
      {showModal && (
        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <div className='fixed inset-0 transition-opacity' aria-hidden='true'>
              <div className='absolute inset-0 bg-gray-500 opacity-75' onClick={() => setShowModal(false)} />
            </div>


            {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
            <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>&#8203;</span>
            <div role='dialog' aria-modal='true' aria-labelledby='modal-headline' className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
              <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
			  	<h1 className="mt-1 mb-12 text-lg leading-6 font-medium text-gray-900">{t('Sign in')}
				<button type='button' onClick={() => setShowModal(false)} style={{position: "absolute", top: '8%', right:'5%'}} className='text-base font-medium text-gray-700 hover:bg-gray-50'>{'X'}</button>
				<hr className="mt-4" style={{ backgroundImage:'linear-gradient(to right, #0B81BD, #B52C76)', height: 2.5}}/>
				</h1>
                <div className='sm:flex sm:flex-col sm:items-center'>				
                  <div className='mt-3 text-center sm:mt-0 sm:text-left'>
                    <h3 className='text-lg leading-6 font-medium text-gray-900' id='modal-headline'>{t('Code')}</h3>
                  </div>
                  <div className='flex mt-5 mb-3 w-full justify-center'>
                    <input type="text" ref={pinField} maxLength='6' id='pinField'
                      className={classnames('pin-input', { 'correct': passwordStatus === 1, 'incorrect': passwordStatus === -1 })}
                      disabled={passwordStatus === 1}
					  onChange={onPinChange} 
					  style={{width: '70%'}}/>
					</div>
				  	<button onClick={submitPassword} type="button" className="group relative w-1/4 mt-12 justify-center py-2 border text-sm font-medium text-black bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
					style={{
					border:'solid 2px transparent',
					backgroundImage:'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #0B81BD, #B52C76)',
					backgroundOrigin:'border-box',
					backgroundClip:'content-box, border-box',
					boxShadow:'2px 1000px 1px #fff inset',
					}}>{t('Validate')}</button>			 			  
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
